import React, { useEffect } from 'react'
import axios from 'axios'
import Markdown from 'react-markdown'

type Props = {}

type Content = {
    Description: string
    AboutUs: string
    Color: string
    TitleAboutUs: string
    createdAt: string
    updatedAt: string
    publishedAt: string
    Name: string
    slug: string
    Logo: {
        data: {
            id: number
            attributes: {
                name: string
                alternativeText: string
                caption: string
                width: number
                height: number
                formats: {
                    thumbnail: {
                        name: string
                        hash: string
                        ext: string
                        mime: string
                        path: string
                        width: number
                        height: number
                        size: number
                        url: string
                    }
                }
                hash: string
                ext: string
                mime: string
                size: number
                url: string
                previewUrl: string
                provider: string
                provider_metadata: string
                createdAt: string
                updatedAt: string
            }
        }
    }
    CoverImage: {
        data: {
            id: number
            attributes: {
                name: string
                alternativeText: string
                caption: string
                width: number
                height: number
                formats: string
                hash: string
                ext: string
                mime: string
                size: number
                url: string
                previewUrl: string
                provider: string
                provider_metadata: string
                createdAt: string
                updatedAt: string
            }
        }
    }
}

const AboutUs = (props: Props) => {

    const [content, setContent] = React.useState({} as Content);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);

    useEffect(() => {
        setLoading(true)
         axios.get(`${process.env.REACT_APP_API_URL}/api/website-configs/${process.env.REACT_APP_COMPANY_ID}?populate=*`)
            .then(res => {
                setContent(res.data.data.attributes)
                console.log(res.data.data.attributes)
                setLoading(false)

            })
            .catch(err => {
                console.log(err)
                setError(true)
            })

    }, [])

    if (loading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error</div>
    }

    return (
        <article id="post-81" className="post-81 page type-page status-publish hentry">
            <header className="entry-header">
                <h1 className="entry-title">{content.Name}</h1>
            </header>
            <div className="entry-content">
                {/* <div dangerouslySetInnerHTML={{ __html: content.AboutUs }} /> */}
                <Markdown>{content.AboutUs}</Markdown>
            </div>
        </article>
    )
}

export default AboutUs