import React from 'react'
import { Link } from "react-router-dom";
import Tile from '../../components/Tile/Tile'
import axios from 'axios';

type Props = {}

const Home = (props: Props) => {

    const [content, setContent] = React.useState({} as any);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);

    React.useEffect(() => {
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/api/articles?filters[$and][0][webconfig][slug][$contains]=${process.env.REACT_APP_COMPANY_SLUG}&filters[$and][0][category][Title][$eq]=Home&populate=*`)
            .then(res => {
                console.log(res.data.data)
                setContent(res.data.data)
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setError(true)
            })
    }, [])


    return (
        <div className="blocks">
            <ul className="clearfix">

                {loading && <div>Loading...</div>}
                {error && <div>Error</div>}
                {content.length && content?.map((item: any, index: number) => {
                    item.attributes.Id = item.id
                    return (
                        <Tile item={item.attributes} key={item.id} />
                    )
                })}
            </ul>
        </div>
    )
}

export default Home