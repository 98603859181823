import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom';

type Props = {}

const Footer = (props: Props) => {

    const [content, setContent] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);

    React.useEffect(() => {
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/api/articles?populate=*&filters[$and][0][category][Title][$eq]=Footer&filters[$and][0][webconfig][Slug][$eq]=${process.env.REACT_APP_COMPANY_SLUG}`)
            .then(res => {
                setContent(res.data.data)
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setError(true)
            })

    }, [])

    if (loading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error</div>
    }

    return (
        <footer id="footer" className="site-footer" role="contentinfo">
            <ul id="menu-footer" className="footer_menu">

                {content.length && content?.map((item: any, index: number) => {
                    return (
                        <li id="menu-item-130" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-130" key={index}>
                           <Link to={`/articles/${item.id}`}>{item.attributes.Title}</Link>
                        </li>
                    )
                })}
            </ul>
        </footer>
    )
}

export default Footer