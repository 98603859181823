import React from 'react'
import axios from 'axios'
// get id from url with react-router-dom
import { useParams } from 'react-router-dom';
import Markdown from 'react-markdown'

type Props = {}

const Article = (props: Props) => {

    const [content, setContent] = React.useState({} as any);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);

    const { id } = useParams();

    React.useEffect(() => {
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/api/articles/${id}?populate=*&filters[$and][0][website_config][slug][$eq]=${process.env.REACT_APP_COMPANY_SLUG}`)
            .then(res => {
                console.log(res.data.data)
                setContent(res.data.data.attributes)
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setError(true)
            })

    }, [id])

    if (loading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error</div>
    }


    return (
        <article id="post-81" className="post-81 page type-page status-publish hentry">
            <header className="entry-header">
                <h1 className="entry-title">{content.Title}</h1>
            </header>
            <div className="entry-content">
                <Markdown>{content.Description}</Markdown>
            </div>
        </article>
    )
}

export default Article