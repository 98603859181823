import React, { ReactNode, useEffect } from 'react'
import axios from 'axios'
import Footer from './components/Footer/Footer'
import Navbar from './components/Navbar/Navbar'
import Loader from './components/Loader/Loader'

type Props = {
    children: ReactNode
}

type Config = {
    CompanyName: string
    Color: string
    Logo: {
        data: {
            attributes: {
                url: string,
                alternativeText: string
            }
        }
    } 
    CoverImage: {
        data: {
            attributes: {
                url: string
            }
        }
    }
}


const initConf: Config = {
    CompanyName: '',
    Color: '',
    Logo: {
        data: {
            attributes: {
                url: '',
                alternativeText: ''
            }
        }
    }, 
    CoverImage: {
        data: {
            attributes: {
                url: ''
            }
        }
    }
}


const MainLayout = (props: Props) => {

    const [config, setConfig] = React.useState<Config>(initConf)
    const [isLoading, setIsLoading] = React.useState<boolean>(true)

    useEffect(() => {
        setIsLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/api/website-configs/${process.env.REACT_APP_COMPANY_ID}?populate=*`)
            .then(res => {
                setConfig(res.data.data.attributes);
                window.document.title = res.data.data.attributes.Name
                setTimeout(() => {
                    setIsLoading(false)
                }, 500);
            })
    }, [])




    return (
        <>
            <Loader isLoading={isLoading} />
            < Navbar {...config} />
            <div className="container">
                <div className="sixteen columns">
                    <div className="head_banner">
                    <img className="logo" src={`${process.env.REACT_APP_API_URL}${config.CoverImage.data.attributes.url}`} alt="" />
                    {/* <img className="logo" src={`${process.env.PUBLIC_URL}/Header.jpg`} alt="Химвектор" /> */}
                    </div>
                    {props.children}
                </div>
            </div>

            <Footer />
        </>
    )
}

export default MainLayout