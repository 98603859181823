import React from 'react'
import axios from 'axios'
import Tile from '../../components/Tile/Tile'

type Content = {
    Title: string,
    Description: string,
}


type Props = {}

const Electro = (props: Props) => {

    const [content, setContent] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);

    React.useEffect(() => {
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/api/articles?filters[$and][0][webconfig][slug][$contains]=${process.env.REACT_APP_COMPANY_SLUG}&filters[$and][0][category][Title][$eq]=Electricity&populate=*`)
            .then(res => {
             
                setContent(res.data.data.map((item: any) => {
                    item.attributes.Id = item.id
                    return item
                }))
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setError(true)
            })

    }, [])

    if (loading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error</div>
    }

    return (
        <article id="post-191" className="post-191 page type-page status-publish hentry">

            <header className="entry-header">
                <h1 className="entry-title">Постачання електроенргії</h1>
            </header>

            <div className="blocks">
                <ul className="clearfix">
                    {content.length && content.map((item: any) => {
                        return (
                            <Tile item={item.attributes} key={item.id} />
                        )
                    })
                    }
                </ul>
            </div>
        </article>
    )
}

export default Electro