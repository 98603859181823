import React from "react";
import { ReactNode } from "react";
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import Home from "./pages/Home/Home";
import AboutUs from "./pages/AboutUs/AboutUs";
import Electro from "./pages/Electro/Electro";
import Article from "./pages/Article/Article";

type Props = {}

const Routers = (props: Props) => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/About" element={<AboutUs />} />
            <Route path="/gas-supply" element={<Home />} />
            <Route path="/electro-main" element={<Electro />} />
            <Route path='/articles/:id' element={<Article />} />
        </Routes>
    )
}

export default Routers