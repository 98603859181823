import React from 'react'
import { Link } from 'react-router-dom'

type Props = {
    item: {
        Title: string,
        Image: any,
        Id: number
    }
}

const Tile = (props: Props) => {
    const { item } = props
    console.log(item)
    return (
        <li>
            <Link to={`/articles/${item?.Id}`}>
                <p>
                    <span className="span__hover">{item.Title}</span>
                    <span className="span__default">{item.Title}</span>
                </p>
                <div>
                    <img src={`${process.env.REACT_APP_API_URL}${item?.Image?.data?.attributes?.url}`} />
                </div>
            </Link>
        </li>
    )
}

export default Tile