import React from 'react';
import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home/Home';
import Footer from './components/Footer/Footer';

import { BrowserRouter as Router} from 'react-router-dom'
import MainLayout from "./MainLayout";
import Routers from './Routers';

function App() {
  return (
    <Router>
      <MainLayout>
        <Routers />
      </MainLayout>
    </Router>
  );
}

export default App;
