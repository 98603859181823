import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Loader from '../Loader/Loader'


type Props = {}
type Config = {
    CompanyName: string
    Color: string
    Logo: {
        data: {
            attributes: {
                url: string,
                alternativeText: string
            }
        }
    }
}

const initConf: Config = {
    CompanyName: '',
    Color: '',
    Logo: {
        data: {
            attributes: {
                url: '',
                alternativeText: ''
            }
        }
    }
}

const Navbar = (props: Config) => {

    const config = props || initConf

    return (
        <div className="head-container">
            <div className="container">
                <div className="sixteen columns">
                    <header id="masthead" className="site-header" role="banner">

                        <hgroup>
                            <span className="head_logo">
                                <Link to="/" title={config.Logo.data.attributes.alternativeText} rel="home">
                                    <img className="logo" src={`${process.env.REACT_APP_API_URL}` + config.Logo.data.attributes.url} alt={config.Logo.data.attributes.alternativeText} />
                                </Link>
                            </span>
                            <Link className="head_text" to="/" title="" rel="home">
                                <h2 className="site-description">{config.CompanyName}
                                </h2>
                            </Link>
                        </hgroup>

                        <nav role="navigation" className="site-navigation main-navigation">
                            <div className="menu-header-container">
                                <ul id="menu-header" className="menu">
                                    <li id="menu-item-34" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-34">
                                        <Link to="/about/">
                                            Про компанію
                                        </Link>
                                    </li>
                                    <li id="menu-item-36" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-36">
                                        <Link to="/gas-supply/">
                                        Постачання газу
                                        </Link>
                                    </li>
                                    <li id="menu-item-222" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-222">
                                        <Link to="/electro-main/">
                                            Постачання електроенргії
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </nav>

                    </header>
                </div>
            </div>
        </div>

    )
}

export default Navbar

